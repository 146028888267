/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const FaqMarkup = ({ faqSection }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              ${faqSection.map((section) =>
                JSON.stringify({
                  "@type": "Question",
                  name: section.question,
                  acceptedAnswer: { "@type": "Answer", text: section.answer },
                })
              )}
            ]
          }
        `,
      }}
    />
  )
}

export default FaqMarkup
