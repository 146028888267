/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const AuthorMarkup = ({
  url,
  title,
  description,
  dateModified,
  datePublished,
}) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://www.formelskin.de/${url}/"
            },
            "headline": "${title}",
            "description": "${description ? description : ""}",
            "image": "https://www.formelskin.de/dr-sarah-bechstein.jpg",
            "author": {
              "@type": "Person",
              "name": "Dr. med. Sarah Bechstein",
              "honorificPrefix": "Dr.",
              "honorificSuffix": "M.D."
            },  
            "publisher": {
              "@type": "Organization",
              "name": "FORMEL Skin GmbH",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.formelskin.de/logo.png",
                "width": 438,
                "height": 42
              }
            },
            "datePublished": "${datePublished}",
            "dateModified": "${dateModified}"
          }
        `,
      }}
    />
  )
}

export default AuthorMarkup
