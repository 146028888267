/** @jsx jsx */
import { useEffect, useState } from "react"

import { Container } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

function useScript(src) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  })

  useEffect(
    () => {
      // Create script
      let script = document.createElement("script")
      script.src = src
      script.async = true

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false,
        })
      }

      const onScriptError = () => {
        script.remove()

        setState({
          loaded: true,
          error: true,
        })
      }

      script.addEventListener("load", onScriptLoad)
      script.addEventListener("error", onScriptError)

      // Add script to document body
      document.body.appendChild(script)

      // Remove event listeners on cleanup
      return () => {
        script.removeEventListener("load", onScriptLoad)
        script.removeEventListener("error", onScriptError)
      }
    },
    [src] // Only re-run effect if script src changes
  )

  return [state.loaded, state.error]
}

const ProvenExpertRating = () => {
  useScript(
    "https://www.provenexpert.com/widget/richsnippet.js?u=1VUBlOwZlHGp1NGZ5NKZmOUpk4TB4Hwo&v=2"
  )

  return (
    <Container>
      <div sx={{ my: 6, textAlign: "center" }}>
        <div className="pe-richsnippets" />
      </div>
    </Container>
  )
}

export default ProvenExpertRating
