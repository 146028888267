const BLOG_POSTS_BLACKLIST = [
  "/akne/medikamente/differin",
  "/akne/medikamente/aknemycin",
  "/akne/medikamente/duac",
  "/akne/medikamente/spironolacton",
  "/akne/medikamente/benzaknen",
  "/akne/medikamente/dipalen",
  "/akne/medikamente/zindaclin",
  "/akne/medikamente/aknederm",
  "/akne/medikamente/nadixa",
  "/akne/medikamente/isotrexin",
  "/akne/medikamente/clienzo",
  "/akne/medikamente/aknefug",
  "/akne/medikamente/ureotop-vas",
  "/akne/medikamente/akneroxid",
  "/akne/medikamente/carbamid",
  "/rosacea/medikamente/dermalex",
  "/akne/medikamente/bpo-combustin",
  "/akne/medikamente/acne-plus",
  "/akne/medikamente/aknefug-oxid",
  "/akne/medikamente/aknefug-liquid",
  "/akne/behandeln/medikamente",
  "/akne/akne-behandlung/medikamente",
  "/akne/medikamente-2",
]

export default BLOG_POSTS_BLACKLIST
