/** @jsx jsx */
import { Fragment } from "react"

import { jsx } from "@trueskin-web/theme"

import BLOG_POSTS_BLACKLIST from "../../blog-posts-blacklist"

const Blacklisted = ({ href, children }) => {
  const isBlacklisted = BLOG_POSTS_BLACKLIST.includes(href)

  if (isBlacklisted) {
    return null
  }

  return <Fragment>{children}</Fragment>
}

export default Blacklisted
