/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { Fragment, useEffect } from "react"

import { trackingClient } from "@trueskin-web/apis"
import {
  Container,
  ExpansionPanel,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Slugs, useTranslation } from "@trueskin-web/translations"

import BlogHero from "../block-library/blog-hero"
import BlogRelated from "../block-library/blog-related"
import AuthorMarkup from "../block-library/components/author-markup"
import Blocks from "../block-library/components/blocks"
import BlogPostLink from "../block-library/components/blog-post-link"
import Breadcrumbs from "../block-library/components/breadcrumbs"
import FaqMarkup from "../block-library/components/faq-markup"
import MoreInformationLinks from "../block-library/components/more-information-links"
import ProvenExpertRating from "../block-library/components/proven-expert-rating"
import SEODetails from "../block-library/components/seo-details"
import FaqContainer from "../components/faq-container"
import Layout from "../components/layout"

import BlogTableOfContent from "./blog-content"

const BlogPostBreadcrumbs = ({ blogPost }) => {
  const { t } = useTranslation()

  return (
    <Breadcrumbs
      items={[
        { title: t("Templates.BlogPost.title"), href: Slugs["skin-guide"].url },
        {
          title: blogPost.blogSubcategory.blogCategory.name,
          href: `/${blogPost.blogSubcategory.blogCategory.slug}`,
        },
        {
          title: blogPost.blogSubcategory.name,
          href: `/${blogPost.blogSubcategory.blogCategory.slug}/${blogPost.blogSubcategory.slug}`,
        },
        {
          title: blogPost.title,
        },
      ]}
    />
  )
}

const BlogPost = ({
  data: {
    strapi: { blogPost, blogPostToc },
  },
}) => {
  const location = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    trackingClient.trackCampaignIfPresent(location)
  }, [location])

  return (
    <Fragment>
      <AuthorMarkup
        url={`${blogPost.blogSubcategory.blogCategory.slug}/${blogPost.blogSubcategory.slug}/${blogPost.slug}`}
        title={blogPost.title}
        description={blogPost.seoDetails && blogPost.seoDetails.metaDescription}
        datePublished={blogPost.createdAt}
        dateModified={blogPost.updatedAt}
      />

      {blogPost.showFaq && <FaqMarkup faqSection={blogPost.faqSection} />}

      <Layout title={blogPost.blogSubcategory.blogCategory.name}>
        <SEODetails title={blogPost.title} {...blogPost.seoDetails} />

        {blogPost.headerBlock?.length ? (
          <div sx={{ mt: [5, 7], mb: 5 }}>
            <Container>
              <Blocks blocks={blogPost.headerBlock} />
              <BlogPostBreadcrumbs blogPost={blogPost} />
            </Container>
          </div>
        ) : (
          <BlogHero
            categoryLayout
            {...blogPost.hero}
            hideConversionElement={!blogPost.showConversionElement}
            breadcrumbs={<BlogPostBreadcrumbs blogPost={blogPost} />}
          />
        )}

        <div sx={{ mt: [null, null], mb: 9 }}>
          <Container>
            <BlogTableOfContent
              toc={blogPostToc}
              blocks={blogPost.blocks}
              author={blogPost.pageAuthor}
              hideTableOfContents={blogPost.hideTableOfContents}
            />
          </Container>
        </div>

        <ProvenExpertRating />

        <div sx={{ mt: [null, null], mb: 9 }}>
          <Container>
            {blogPost?.relatedArticles?.length > 0 && (
              <BlogRelated
                heading={t("Templates.BlogPost.relatedArticles")}
                headingProps={{
                  as: "h3",
                  sx: {
                    width: ["calc(100% + 32px)", "100%"],
                    mx: [-4, 0],
                    textAlign: "center",
                    py: "18px",
                    mb: 7,
                  },
                }}
                component={BlogPostLink}
                items={blogPost.relatedArticles.map((relatedBlogPost) => ({
                  title: relatedBlogPost.title,
                  blogPost: relatedBlogPost,
                  blogSubcategory: relatedBlogPost.blogSubcategory,
                }))}
              />
            )}
          </Container>
        </div>

        {blogPost.showFaq && blogPost.faqSection && blogPost.faqSection.length && (
          <div sx={{ mt: [null, null], mb: 9 }}>
            <Container>
              <FaqContainer>
                {blogPost.faqSection.map(({ question, answer }, index) => (
                  <ExpansionPanel key={index} title={question}>
                    <UserGeneratedHtml html={answer} />
                  </ExpansionPanel>
                ))}
              </FaqContainer>
            </Container>
          </div>
        )}

        <MoreInformationLinks linksHtml={blogPost.preFooterLinks} />

        {/*
              {blogPost.relatedArticles.length > 0 && (

                  <div sx={{ display: "grid", gap: [6, 7] }}>
                    {blogPost.relatedArticles.map((relatedBlogPost) => (
                      <BlogPostLink
                        key={relatedBlogPost.title}
                        blogSubcategory={relatedBlogPost.blogSubcategory}
                        blogPost={relatedBlogPost}
                      />
                    ))}
                  </div>
                </div>
              )}
        */}
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  query ($blogPostId: ID!) {
    strapi {
      blogPost(id: $blogPostId) {
        slug
        title
        description
        hideTableOfContents
        showFaq
        faqSection {
          question
          answer
        }
        hero {
          ...BlogHeroFragment
        }
        seoDetails {
          ...SEODetailsFragment
        }
        pageAuthor {
          title
          description
          doctor {
            name
            specialty
            slug
            image {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        primaryCta {
          label
          targetUrl
        }
        showConversionElement
        badges {
          title
          image {
            url
          }
          width
        }
        blogSubcategory {
          name
          slug
          blogCategory {
            name
            slug
          }
        }
        headerBlock {
          __typename
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
        }
        blocks {
          __typename
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
          ... on Strapi_ComponentAtomsImage {
            ...ImageFragment
          }
          ... on Strapi_ComponentAtomsSpace {
            ...SpaceFragment
          }
          ... on Strapi_ComponentBlogVideo {
            ...VideoFragment
          }
          ... on Strapi_ComponentLandingContentCtaList {
            ...ContentCtaListFragment
          }
          ... on Strapi_ComponentBlocksContentCta {
            ...ContentCtaFragment
          }
          ... on Strapi_ComponentBlocksBlogPitch {
            ...BlogPitchFragment
          }
          ... on Strapi_ComponentBlocksHowItWorks {
            ...HowItWorksFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksVisually {
            ...HowItWorksVisuallyFragment
          }
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksPrStrip {
            ...PrStripFragment
          }
          ... on Strapi_ComponentBlocksConditionsList {
            ...ConditionsListFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksGuide {
            ...HowItWorksGuideFragment
          }
          ... on Strapi_ComponentBlocksMedicalIntro {
            ...MedicalIntroFragment
          }
          ... on Strapi_ComponentBlocksSkinGuideIndex {
            ...SkinGuideIndexFragment
          }
          ... on Strapi_ComponentBlocksSetIntro {
            ...SetIntroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksProgressBoard {
            ...ProgressBoardFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
          ... on Strapi_ComponentLandingList {
            ...ListFragment
          }
          ... on Strapi_ComponentLandingContentCtaBadges {
            ...ContentCtaBadgesFragment
          }
          ... on Strapi_ComponentLandingBadges {
            ...BadgesFragment
          }
          ... on Strapi_ComponentBlocksProcess {
            ...ProcessFragment
          }
          ... on Strapi_ComponentBlocksHighlights {
            ...HighlightsFragment
          }
          ... on Strapi_ComponentBlocksContentVideo {
            ...ContentVideoFragment
          }
          ... on Strapi_ComponentBlocksReviews {
            ...ReviewsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsIntro {
            ...DoctorsIntroFragment
          }
          ... on Strapi_ComponentBlocksProductsHighlight {
            ...ProductsHighlightFragment
          }
          ... on Strapi_ComponentBlocksBeforeAfter {
            ...BeforeAfterFragment
          }
          ... on Strapi_ComponentBlocksMirroredHighlights {
            ...MirroredHighlightsFragment
          }
          ... on Strapi_ComponentBlocksPrefooter {
            ...PrefooterFragment
          }
          ... on Strapi_ComponentBlocksDoctors {
            ...DoctorsFragment
          }
          ... on Strapi_ComponentBlocksShowcaseProducts {
            ...ShowcaseProductsFragment
          }
          ... on Strapi_ComponentBlocksTreatmentGoals {
            ...TreatmentGoalsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsBoard {
            ...DoctorsBoardFragment
          }
          ... on Strapi_ComponentBlocksFaqs {
            ...FaqsFragment
          }
          ... on Strapi_ComponentBlocksInstagramWidget {
            ...InstagramWidgetFragment
          }
          ... on Strapi_ComponentBlocksSocialReviews {
            ...SocialReviewsFragment
          }
          ... on Strapi_ComponentBlocksMedicHero {
            ...HeroMedicFragment
          }
          ... on Strapi_ComponentBlocksDoctorTeam {
            ...DoctorTeamFragment
          }
          ... on Strapi_ComponentBlocksDoctorMembers {
            ...DoctorMembersFragment
          }
          ... on Strapi_ComponentBlocksTitle {
            ...TitleFragment
          }
          ... on Strapi_ComponentBlocksThreePartContent {
            ...ThreePartContentFragment
          }
          ... on Strapi_ComponentBlocksZigZag {
            ...ZigZagFragment
          }
          ... on Strapi_ComponentBlocksConnector {
            ...ConnectorFragment
          }
          ... on Strapi_ComponentBlocksTimeline {
            ...TimelineFragment
          }
          ... on Strapi_ComponentBlocksProductCards {
            ...ProductCardsFragment
          }
          ... on Strapi_ComponentBlocksIngredientCards {
            ...IngredientCardsFragment
          }
          ... on Strapi_ComponentBlocksCtaBlock {
            ...CtaBlockFragment
          }
          ... on Strapi_ComponentBlocksNewsletterSignup {
            ...NewsletterSignupFragment
          }
        }
        updatedAt
        createdAt
        preFooterLinks
        relatedArticles {
          title
          slug
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 500, aspectRatio: 1, layout: CONSTRAINED)
              }
            }
          }
          readingTime
          blogSubcategory {
            slug
            blogCategory {
              slug
            }
          }
        }
      }
      blogPostToc(where: { id: $blogPostId })
    }
  }
`

export default BlogPost
