/** @jsx jsx */
import { Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

const Toc = ({ toc, csx }) =>
  toc ? (
    <div sx={{ ...csx }}>
      <Heading
        as="h3"
        sx={{
          width: ["calc(100% + 32px)", "100%"],
          mx: [-4, 0],
          mb: 5,
          py: "18px",
          bg: "grey",
          textAlign: "center",
        }}
      >
        <Trans i18nKey="Templates.ToC.title" />
      </Heading>

      <nav>
        <div
          sx={{
            ul: { listStyle: "none", m: 0, p: 0 },
            a: {
              display: "block",
              color: "primary",
              // textDecoration: "none",
              textDecorationLine: "underline",
            },
            "li:not(:last-of-type)": {
              mb: 4,
              // pb: 3,
              // borderBottom: "1px",
            },
          }}
          dangerouslySetInnerHTML={{ __html: toc }}
        />
      </nav>
    </div>
  ) : null

export default Toc
