/** @jsx jsx */
import { GatsbyImage } from "gatsby-plugin-image"

import { Icon, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { Trans } from "@trueskin-web/translations"

const BlogPostLink = ({ className, blogSubcategory, blogPost }) => (
  <TextLink
    className={className}
    href={`/${blogSubcategory.blogCategory.slug}/${blogSubcategory.slug}/${blogPost.slug}`}
    isTextColor
    sx={{
      //   // display: "grid",
      //   // gridTemplateColumns: ["80px 1fr", "minmax(80px, 3fr) 7fr"],
      //   // alignItems: "flex-start",
      //   // gap: [3, 5],
      //   display: "flex",
      //   flexDirection: "column",
      //   // paddingBottom: [4, 6],
      display: "block",
      width: ["260px", "100%"],
      height: "100%",
      borderBottom: "1px",
    }}
  >
    {/* {blogPost.image && (
      <Img
        fluid={blogPost.image.imageFile.childImageSharp.fluid}
        sx={{ maxHeight: "260px" }}
      />
    )} */}

    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      }}
    >
      {blogPost.image && (
        <GatsbyImage
          image={blogPost.image.imageFile.childImageSharp.gatsbyImageData}
          alt=""
          sx={{
            width: ["260px", "100%"],
            maxHeight: ["260px", "initial"],
            mb: 5,
          }}
        />
      )}

      <div
        sx={{
          fontSize: [null, 5],
          lineHeight: [null, "smallerHeading"],
          mb: 5,
        }}
      >
        {blogPost.title}
      </div>

      {/* <div sx={{ fontSize: 0, fontFamily: "monospace" }}>
        {blogPost.readingTime} min read
      </div> */}
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // fontSize: [4, 5],
          lineHeight: "heading",
        }}
      >
        <Trans i18nKey="BlogPostLink.readMore" />
        <Icon icon={arrowRightIcon} />
      </div>
    </div>
  </TextLink>
)

export default BlogPostLink
