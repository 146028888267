/** @jsx jsx */
import { Fragment } from "react"

import { Icon, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"

const Breadcrumb = ({ title, href, position, isLastChild }) => {
  return (
    <Fragment>
      <li
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
        sx={{ fontSize: 0, fontFamily: "monospace" }}
      >
        {isLastChild ? (
          <span itemProp="name">{title}</span>
        ) : (
          <TextLink
            itemScope
            itemType="https://schema.org/WebPage"
            itemProp="item"
            itemID={href}
            href={href}
            isTextColor
          >
            <span itemProp="name">{title}</span>
          </TextLink>
        )}
        <meta itemProp="position" content={position} />
      </li>
      {!isLastChild && <Icon icon={arrowRightIcon} size="sm" sx={{ mx: 2 }} />}
    </Fragment>
  )
}

const Breadcrumbs = ({ items }) => (
  <nav sx={{ fontSize: 0, fontFamily: "monospace", mb: 5 }}>
    <ol
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        listStyle: "none",
        paddingLeft: 0,
      }}
    >
      {items.map((item, index) => (
        <Breadcrumb
          key={item.title}
          title={item.title}
          href={item.href}
          position={index + 1}
          isLastChild={items.length === index + 1}
        />
      ))}
    </ol>
  </nav>
)

export default Breadcrumbs
