/** @jsx jsx */
import { Container, Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

const MoreInformationLinks = ({ linksHtml }) => {
  if (linksHtml) {
    return (
      <div sx={{ py: [6, 7], bg: "lightGray" }}>
        <Container>
          <Heading size="md" mb={[5, 6]} sx={{ textAlign: [null, "center"] }}>
            <Trans i18nKey="MoreInformationLinks.title" />
          </Heading>

          <div
            sx={{
              ul: {
                display: "grid",
                columnGap: [5, 6],
                rowGap: [2, 3],
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                alignItems: "flex-start",
                listStyle: "none",
                m: 0,
                p: 0,
              },
              a: {
                color: "text",
                textDecoration: "none",
                "&:hover, &:focus": {
                  color: "primaryLight",
                },
              },
            }}
            dangerouslySetInnerHTML={{ __html: linksHtml }}
          />
        </Container>
      </div>
    )
  }

  return null
}

export default MoreInformationLinks
